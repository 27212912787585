import React, { useRef } from 'react'
import { login, isAuthenticated } from '../../../../utils/auth'
import { Pane } from 'evergreen-ui'
import EncounterDetails from '../../../../components/EncounterDetails'
import { useHandleEncounterList } from '../../../../hooks/useHandleEncounterList'

const EncountersPage = ({ params }) => {
  const encounterTable = useRef(null)

  const {
    handleEncounterDetailsClose
  } = useHandleEncounterList({ encounterTable })

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      <EncounterDetails
        encounterId={params['encounterId']}
        realmId={params['realmId']}
        show={true}
        handleDetailsClose={handleEncounterDetailsClose}
        encounterTableRef={encounterTable}
      />
    </>
  )
}

export default EncountersPage
